/**
 * Place this file after the act-on embed code
 * - this will prefill the GA clientID and gclid hidden input fields
 * - the act-on form will need to have the hidden fields below
 * - input[name="Client ID"] and input[name="gclid"]
 */

// eslint-disable-next-line no-undef
(function() {
  // this is the script that adds the GA clientID and Gclid to the Act-on's embeded form
  function readCookie(name) {
    let nameEQ = encodeURI(name) + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return decodeURI(c.substring(nameEQ.length, c.length));
    }
    return null;
  }

  // from https://stackoverflow.com/questions/5525071/how-to-wait-until-an-element-exists
  function waitForElm(selector) {
    return new Promise(resolve => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }
        const observer = new MutationObserver(mutations => {
            if (document.querySelector(selector)) {
                resolve(document.querySelector(selector));
                observer.disconnect();
            }
        });
        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
  }

  // using the Act-on form class to check if the form is added to the DOM
  waitForElm('.ao-form').then((elm) => {
    const clientIdInput = document.querySelector('input[name="Client ID"]')
    const clientIdInput2 = document.querySelector('input[name="ClientID"]')
    const gclidInput = document.querySelector('input[name="gclid"]')
    const gclidInput2 = document.querySelector('input[name="Gclid"]')
    const cookieClientId = readCookie('_ga') ? readCookie('_ga').split(".").slice(2).join(".") : "";
    const cookieGclid = readCookie("adwordsLead") || "";

    if (clientIdInput) clientIdInput.value = cookieClientId;
    if (clientIdInput2) clientIdInput2.value = cookieClientId;
    if (gclidInput) gclidInput.value = cookieGclid;
    if (gclidInput2) gclidInput2.value = cookieGclid;
  });

})()